import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { S25TableComponent } from "../../s25-table/s25.table.component";
import { Season, SeasonsService } from "./seasons.service";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { GenericTableButtonComponent } from "../../s25-table/generics/generic.table.button.component";
import { S25Util } from "../../../util/s25-util";
import { UserprefService } from "../../../services/userpref.service";
import { jSith } from "../../../util/jquery-replacement";
import { Table } from "../../s25-table/Table";
import Cell = Table.Cell;
import { S25Datefilter } from "../../s25-dateformat/s25.datefilter.service";
import { StudentHousingService } from "../student.housing.service";
import { Rules } from "../../s25-rule-tree/s25.rule.const";
import { RuleTreeService } from "../../../services/rule.tree.service";
import { S25RuleTreeUtil } from "../../s25-rule-tree/s25.rule.tree.util";

@TypeManagerDecorator("s25-ng-seasons")
@Component({
    selector: "s25-ng-seasons",
    template: `
        @if (!editingSeasonClone) {
            <s25-ng-table
                [caption]="'Seasons'"
                [unlimitedWidth]="true"
                [columnSortable]="false"
                [dataSource]="dataSource"
                [hasTotalRowCount]="true"
                [hasRefresh]="true"
                [showHeaderWhenNoData]="true"
            ></s25-ng-table>
            <button (click)="addNew()" class="c-margin-top--single aw-button aw-button--outline">Add New</button>
        }

        @if (editingSeasonClone) {
            <s25-ng-season class="ngBlock" [season]="editingSeasonClone"></s25-ng-season>
            <div class="c-margin-top--single">
                <s25-ng-button [type]="'primary'" [onClick]="save">Save</s25-ng-button>
                <button (click)="cancel()" class="c-margin-left--single aw-button aw-button--danger--outline">
                    Cancel
                </button>
            </div>
        }

        @if (!editingSeasonClone) {
            <s25-ng-checkbox class="ngBlock c-margin-top--single" [parentCd]="cd" [(modelValue)]="rollover"
                >Roll Over</s25-ng-checkbox
            >
            @if (rollover) {
                <div class="c-margin-top--single">
                    <label
                        >From:
                        <s25-ng-season-dropdown
                            class="ngBlock c-margin-bottom--single"
                            [(selectedSeason)]="rollOverFrom"
                        ></s25-ng-season-dropdown>
                    </label>
                    <label
                        >To:
                        <s25-ng-season-dropdown
                            class="ngBlock c-margin-bottom--single"
                            [(selectedSeason)]="rollOverTo"
                        ></s25-ng-season-dropdown>
                    </label>
                    <s25-ng-button [type]="'primary'" [onClick]="rollOver">Roll Over</s25-ng-button>
                </div>
            }
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonsComponent implements OnInit {
    @ViewChild(S25TableComponent) table: S25TableComponent;

    editingSeason: Season = null;
    editingSeasonClone: Season = null;
    rollOverFrom: Season;
    rollOverTo: Season;
    dataSource: Table.Unpaginated;
    addingNew: boolean;
    dateFormat: string;
    rollover: boolean = false;

    constructor(public cd: ChangeDetectorRef) {}

    edit = (row: Table.Row) => {
        this.editingSeason = row.data as Season;
        this.editingSeasonClone = S25Util.clone(this.editingSeason);
        this.addingNew = false;
        this.cd.detectChanges();
    };

    addNew = () => {
        this.editingSeason = {
            housingStartDate: S25Util.date.currentDate(),
            housingEndDate: S25Util.date.currentDate(),
            matchOpenDate: S25Util.date.currentDate(),
            matchCloseDate: S25Util.date.currentDate(),
        };
        this.editingSeasonClone = S25Util.clone(this.editingSeason);
        this.addingNew = true;
        this.cd.detectChanges();
    };

    delete = async (row: Table.Row, instance: GenericTableButtonComponent) => {
        await SeasonsService.deleteSeason(+row.id);
        await this.table.refresh(true, true);
        this.cd.detectChanges();
    };

    cancel = () => {
        this.editingSeason = null;
        this.editingSeasonClone = null;
        this.addingNew = false;
        this.cd.detectChanges();
    };

    save = async () => {
        if (this.addingNew) {
            const [resp, error] = await S25Util.Maybe(SeasonsService.addSeason(this.editingSeasonClone));
            error && S25Util.showError(error);
        } else {
            let saveSeason: Season = {
                seasonId: this.editingSeason.seasonId,
            };
            Object.keys(this.editingSeasonClone).forEach((key) => {
                if (this.editingSeasonClone[key] !== this.editingSeason[key]) {
                    saveSeason[key] = this.editingSeasonClone[key];
                }
            });
            const [resp, error] = await S25Util.Maybe(SeasonsService.updateSeason(saveSeason));
            error && S25Util.showError(error);
        }
        this.cancel();
        await this.table.refresh(true, true);
        this.cd.detectChanges();
    };

    getRows = async () => {
        let rows: Table.Row[] = [];
        let seasons = await SeasonsService.getSeasons();
        if (seasons?.length) {
            jSith.forEach(seasons, (idx, season: Season) => {
                const cells: Table.Row["cells"] = {};
                for (let i = 0; i < this.dataSource.columns.length; i++) {
                    let column = this.dataSource.columns[i];
                    let cell: Cell = {};
                    if (column.type === "date") {
                        cell.text = S25Datefilter.transform(season[column.id], this.dateFormat);
                        cell.sortValue = season[column.id];
                    } else {
                        cell.text = season[column.id];
                    }
                    cells[column.id] = cell;
                }
                rows.push({
                    id: season.seasonId,
                    name: season.seasonId + "",
                    cells: cells,
                    data: season,
                });
            });
        }
        return {
            rows: rows,
            totalRows: S25Util.parseInt(seasons?.length ?? 0),
        };
    };

    rollOver = async () => {
        if (!this.rollOverFrom || !this.rollOverTo) return;

        let promises: Promise<any>[] = [];

        // copy buildings from season to season (overwriting any in to-season)
        promises.push(
            StudentHousingService.getStudentBuildings(this.rollOverFrom.seasonId).then((buildings) => {
                StudentHousingService.setStudentBuildings(this.rollOverTo.seasonId, buildings);
            }),
        );

        // copy questions from season to season (overwriting any in to-season)
        // also copy associated rules from season to season, with overwrite
        promises.push(
            StudentHousingService.getQuestions(this.rollOverFrom.seasonId).then(async (fromQuestions) => {
                return StudentHousingService.replaceSeasonQuestions(
                    this.rollOverTo.seasonId,
                    S25Util.deepCopy(fromQuestions).map((q) => {
                        q.seasonId = this.rollOverTo.seasonId;
                        q.options?.forEach((opt) => {
                            opt.seasonId = this.rollOverTo.seasonId;
                        });
                        return q;
                    }),
                );
            }),
        );

        promises.concat(this.rollOverRules("match", this.rollOverFrom.seasonId, this.rollOverTo.seasonId));
        promises.concat(this.rollOverRules("matchForm", this.rollOverFrom.seasonId, this.rollOverTo.seasonId));

        await S25Util.all(promises);
        this.cd.detectChanges();
    };

    rollOverRules = async (category: Rules.Category, rollOverFromSeasonId: number, rollOverToSeasonId: number) => {
        let promises: Promise<any>[] = [];

        const toSeasonRawRules = await RuleTreeService.getRules(category, true, rollOverToSeasonId);
        const toSeasonRules = S25RuleTreeUtil.parseRules(toSeasonRawRules);

        // delete rules assoc with the current (original) to-season
        for (const rule of toSeasonRules) {
            promises.push(RuleTreeService.delRule(rule.id));
        }

        // wait for questions and rule dels to be done before making new rules for the new set of questions
        await S25Util.all(promises);

        // form newToRules using fromRules
        const fromSeasonRawRules = await RuleTreeService.getRules(category, true, rollOverFromSeasonId);
        const fromSeasonRules = S25RuleTreeUtil.parseRules(fromSeasonRawRules);
        const newToRules = [].concat(fromSeasonRules);
        newToRules.forEach((rule) => {
            rule.id = -1 * Math.ceil((Math.random() + 1) * 100000000);
            rule.subCategory = "" + rollOverToSeasonId;
            rule.name = "S" + rollOverToSeasonId + ": " + rule.name.replace(/^S\d+:\s+/, "");
            promises.push(RuleTreeService.putParsedRule(rule));
        });

        await S25Util.all(promises);
    };

    ngOnInit() {
        this.dataSource = {
            type: "unpaginated",
            dataSource: this.getRows,
            columns: [
                {
                    id: "seasonId",
                    header: "Id",
                },
                {
                    id: "code",
                    header: "Code",
                },
                {
                    id: "housingStartDate",
                    header: "Start",
                    type: "date",
                },
                {
                    id: "housingEndDate",
                    header: "End",
                    type: "date",
                },
                GenericTableButtonComponent.Column("Edit", this.edit, "outline"),
                GenericTableButtonComponent.Column("Delete", this.delete, "danger--outline"),
            ],
        };

        S25Util.all({
            dateFormat: UserprefService.getS25Dateformat(),
        }).then((resp) => {
            this.dateFormat = resp.dateFormat;
            this.cd.detectChanges();
        });
    }
}
