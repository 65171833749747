import { DataAccess, ResponseMessage, UpsertResponseMessage } from "../../dataaccess/data.access";
import { S25Util } from "../../util/s25-util";

export interface MatchStudentHousing {
    bldgId?: number;
    seasonId?: number;
    sortOrder?: number;
    buildingMsg?: string;
    matchGroups?: MatchBuildingMatchGroup[];
    building?: BuildingDoc;
}

export interface MatchBuildingMatchGroup {
    bldgId?: number;
    seasonId?: number;
    matchGroup?: string;
}

export interface MatchRoomMatchGroup {
    roomId?: number;
    seasonId?: number;
    matchGroup?: string;
    room?: {
        roomShort?: string;
    };
}

export interface MatchProfileDesiredBuilding {
    contId?: number;
    bldgId?: number;
    sortOrder?: number;
    building?: BuildingDoc;
}

export interface BuildingDoc {
    bldgId?: number;
    bldgName?: string;
    bldgCode?: string;
}

export interface MatchFriend {
    seasonId?: number;
    condId?: number;
    friendContId?: number;
}

export interface MatchFriendContact {
    contId?: number;
    firstName?: string;
    lastName?: string;
    friendMsg?: string; // null unless mutual
    incoming?: boolean; // initiated by another contact
    outgoing?: boolean; // initiated by calling contact
    matched?: boolean; // has roommates already
}

export interface Answer {
    answerId?: number;
    questionId?: number;
    contId?: number;
    seasonId?: number;
    answer?: string;
    longAnswer?: string;
    question?: Question;
}

export interface MatchProfile {
    contId?: number;
    contact?: DBContact;
    seasonId?: number;
    inviteHash?: string;
    friendMsg?: string;
    segment?: string;
    visible?: boolean;
    matchGroup?: string;
    desiredBuildings?: MatchDesiredBuilding[];
    desiredCaps?: MatchDesiredCap[];
    desiredRoommates?: MatchDesiredRoommate[];
    answers?: Answer[];
    matchRoommateGroupId?: number;
    lastInviteDate?: string;
    eventId?: number;
    roomId?: number;
    roommateUuid?: string;
    pwSet?: boolean;
    status?: "questions" | "buildings" | "search";

    // convenience for front-end only
    friend?: MatchFriendContact;
    imageUri?: string;
    wasRowInserted?: boolean;
    eventName?: string;
    selected?: boolean;

    [key: string]: any;
}

export interface Question {
    questionId?: number;
    question?: string;
    seasonId?: number;
    questionType?: "SELECT" | "MULTISELECT" | "TEXT";
    questionLevel?: "PUBLIC" | "SEARCH_COLUMN" | "FRIENDS" | "ADMIN";
    options?: QuestionOption[];
    sortOrder?: number;
    hidden?: boolean;

    // below are for front-end convenience and will not come from data:
    newAnswer?: {
        text?: string;
    };
    answers?: QuestionOption[];
    singleAnswer?: QuestionOption;
    longAnswer?: string;
    answerId?: number;

    [key: string]: any;
}

export interface QuestionOption {
    questionId?: number;
    option?: string;
    seasonId?: number;
    sortOrder?: number;
}

export interface MatchDesiredBuilding {
    contId?: number;
    bldgId?: number;
    sortOrder?: number;
}

export interface MatchDesiredCap {
    contId?: number;
    capacity?: number;
    sortOrder?: number;
}

export interface MatchDesiredRoommate {
    contId?: number;
    roommateContId?: number;
    sortOrder?: number;
}

export interface DBContact {
    contId?: number;
    internalId?: string;
    firstName?: string;
    familyName?: string;
    email?: string;
    addresses?: DBContactAddress[];
}

export interface DBContactAddress {
    contId?: number;
    addrType?: AddressType; // 3 for work, 4 for home
    email?: string;
    phone?: string;
}

export enum AddressType {
    WORK = 3,
    HOME = 4,
}

export interface MatchProfilesEmail {
    profiles: MatchProfile[];
    emailTo: EmailTo;
}

export interface EmailTo {
    from?: string;
    subject: string;
    body: string;
}

export interface MatchGroup {
    seasonId?: number;
    matchGroup?: string;
    matchGroupId?: number;
}

export class StudentHousingService {
    public static initialize(): Promise<ResponseMessage> {
        return DataAccess.post("/match/initialize.json");
    }

    public static addProfiles(
        profiles: MatchProfile[],
        updateMatchGroup: boolean,
        removeProfileAssignments: boolean,
    ): Promise<MatchProfile[]> {
        return DataAccess.post(
            `/match/profiles.json?updateMatchGroup=${updateMatchGroup}&removeProfileAssignments=${removeProfileAssignments}`,
            profiles,
        );
    }

    public static async getQuestions(seasonId: number): Promise<Question[]> {
        let questions = (await DataAccess.get(`/match/questions.json?seasonId=${seasonId}`)) as Question[];
        questions.sort(S25Util.shallowSort("sortOrder", true));
        questions.forEach((q) => q.options.sort(S25Util.shallowSort("sortOrder", true)));
        return questions;
    }

    public static replaceSeasonQuestions(seasonId: number, questions: Question[]): Promise<ResponseMessage> {
        return DataAccess.post(`/match/questions.json?seasonId=${seasonId}`, questions);
    }

    public static getProfile(inviteHash: string): Promise<MatchProfile> {
        return DataAccess.get(`/match/profile/${inviteHash}.json`);
    }

    public static getStudentBuildings(seasonId: number, matchGroup?: string): Promise<MatchStudentHousing[]> {
        let url = `/match/buildings.json?seasonId=${seasonId}`;
        if (matchGroup) {
            url += `&matchGroup=${matchGroup}`;
        }
        return DataAccess.get(url);
    }

    public static setStudentBuildings(seasonId: number, buildings: MatchStudentHousing[]): Promise<ResponseMessage> {
        return DataAccess.post(`/match/buildings.json?seasonId=${seasonId}`, buildings);
    }

    public static addRoomGroups(seasonId: number, rooms: MatchRoomMatchGroup[]): Promise<ResponseMessage> {
        return DataAccess.post(`/match/roomGroups.json?seasonId=${seasonId}`, rooms);
    }

    public static deleteRoomGroups(seasonId: number, rooms: MatchRoomMatchGroup[]): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/roomGroups.json?seasonId=${seasonId}`, rooms);
    }

    public static getRoomGroups(seasonId: number): Promise<MatchRoomMatchGroup[]> {
        return DataAccess.get(`/match/roomGroups.json?seasonId=${seasonId}`);
    }

    public static getCapacities(seasonId: number, matchGroup: string): Promise<number[]> {
        return DataAccess.get(`/match/capacities.json?seasonId=${seasonId}&matchGroup=${matchGroup}`);
    }

    public static setProfileDesiredBuildings(
        profileInviteHash: string,
        buildings: MatchProfileDesiredBuilding[],
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${profileInviteHash}/buildings.json`, buildings);
    }

    public static setProfileDesiredCap(
        profileInviteHash: string,
        capacities: MatchDesiredCap[],
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${profileInviteHash}/capacities.json`, capacities);
    }

    public static setRoommatePrefs(
        profileInviteHash: string,
        roommatePrefs: MatchDesiredRoommate[],
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${profileInviteHash}/roommatePrefs.json`, roommatePrefs);
    }

    public static setQuestionAnswers(
        seasonId: number,
        profileInviteHash: string,
        answers: Answer[],
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/profile/${profileInviteHash}/answers.json?seasonId=${seasonId}`, answers);
    }

    public static updateProfile(
        profileInviteHash: string,
        contId: number,
        friendMsg: string,
    ): Promise<ResponseMessage> {
        let profile: MatchProfile = { contId: contId, friendMsg: friendMsg };
        return DataAccess.put(`/match/profile/${profileInviteHash}.json`, profile);
    }

    public static getFriends(seasonId: number, profileInviteHash: string): Promise<MatchFriendContact[]> {
        return DataAccess.get(`/match/${profileInviteHash}/friends.json?seasonId=${seasonId}`);
    }

    public static deleteFriend(
        profileInviteHash: string,
        friendContId: number,
        seasonId: number,
    ): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/${profileInviteHash}/friend/${friendContId}.json?seasonId=${seasonId}`);
    }

    public static addFriend(
        profileInviteHash: string,
        seasonId: number,
        friend: MatchFriend,
    ): Promise<ResponseMessage> {
        return DataAccess.post(`/match/${profileInviteHash}/friend.json?seasonId=${seasonId}`, friend);
    }

    public static getProfiles(
        seasonId: number,
        contId: number,
        firstName: string,
        lastName: string,
        questions: { questionId: number; question: string; answer: string }[],
        segments: string[],
        excludeMatched: boolean,
        adminSearch: boolean,
    ): Promise<MatchProfile[]> {
        let url = `/match/${seasonId}/profiles.json?excludeMatched=${excludeMatched}`;
        if (contId) {
            url += `&contId=${contId}`;
        }
        if (firstName) {
            url += `&firstName=${encodeURIComponent(firstName)}`;
        }
        if (lastName) {
            url += `&lastName=${encodeURIComponent(lastName)}`;
        }
        if (segments && segments.length) {
            url += `&segments=${encodeURIComponent(segments.join("+"))}`;
        }
        if (adminSearch) {
            url += `&adminSearch=true`;
        }
        let payload: { [key: number]: string } = {};
        if (questions && questions.length > 0) {
            questions.forEach((q) => (payload[q.questionId] = q.answer));
        }
        return DataAccess.post(url, payload);
    }

    public static getAllProfiles(seasonId: number): Promise<MatchProfile[]> {
        return DataAccess.get(`/match/${seasonId}/all/profiles.json`);
    }

    public static getSegments(seasonId: number): Promise<string[]> {
        return DataAccess.get(`/match/${seasonId}/segments.json`);
    }

    public static getRoommates(profileInviteHash: string): Promise<DBContact[]> {
        return DataAccess.get(`/match/profile/${profileInviteHash}/roommates.json`);
    }

    public static getMatchGroups(seasonId: number): Promise<MatchGroup[]> {
        return DataAccess.get(`/match/${seasonId}/groups.json`);
    }

    public static addMatchGroup(seasonId: number, matchGroup: string): Promise<MatchGroup> {
        return DataAccess.post(`/match/${seasonId}/groups.json?matchGroup=${encodeURIComponent(matchGroup)}`);
    }

    public static deleteMatchGroup(seasonId: number, matchGroup: string): Promise<ResponseMessage> {
        return DataAccess.delete(`/match/${seasonId}/groups.json?matchGroup=${encodeURIComponent(matchGroup)}`);
    }

    public static processSeason(
        seasonId: number,
        segments: string[],
        allowExcessCapacity: boolean,
        allowSingleOccupancy: boolean,
        honorDesiredCap: boolean,
    ): Promise<UpsertResponseMessage<MatchProfile>> {
        let url = `/match/${seasonId}/process.json`;
        let segmentsStr = (segments && segments.length && segments.join("+")) || "";
        url += `?segments=${segmentsStr}`;
        url += `&allowExcessCapacity=${allowExcessCapacity}`;
        url += `&allowSingleOccupancy=${allowSingleOccupancy}`;
        url += `&honorDesiredCap=${honorDesiredCap}`;
        return DataAccess.post(url);
    }

    public static email(
        seasonId: number,
        from: string,
        subject: string,
        body: string,
        profiles: MatchProfile[],
    ): Promise<ResponseMessage> {
        let payload: MatchProfilesEmail = {
            profiles: profiles,
            emailTo: { from: from, subject: subject, body: body },
        };
        return DataAccess.post(`/match/${seasonId}/email.json`, payload);
    }

    public static invite(
        seasonId: number,
        from: string,
        subject: string,
        body: string,
        profiles: MatchProfile[],
    ): Promise<ResponseMessage> {
        let payload: MatchProfilesEmail = {
            profiles: profiles,
            emailTo: { from: from, subject: subject, body: body },
        };
        return DataAccess.post(`/match/${seasonId}/invite.json`, payload);
    }

    public static syncProfile(contId: number): Promise<ResponseMessage> {
        return DataAccess.put(`/match/profile/${contId}/sync.json`);
    }

    public static resetProfile(contId: number): Promise<ResponseMessage> {
        return DataAccess.put(`/match/profile/${contId}/reset.json`);
    }

    public static async getJwt(profileInviteHash: string, pw: string): Promise<string> {
        let [resp, error] = await S25Util.Maybe(
            DataAccess.post(`/match/profile/pwCheck.json?profileInviteHash=${encodeURIComponent(profileInviteHash)}`, {
                strValue: pw,
            }),
        );
        if (!error && resp) {
            return (resp as ResponseMessage).message;
        }
        return "";
    }

    public static async isPwSet(profileInviteHash: string): Promise<boolean> {
        let [resp, error] = await S25Util.Maybe(DataAccess.get(`/match/profile/${profileInviteHash}/isPwSet.json`));
        if (!error && resp) {
            return !!(resp as MatchProfile).pwSet;
        }
        return false;
    }

    public static async pwInit(profileInviteHash: string, pw: string): Promise<ResponseMessage> {
        return DataAccess.post(`/match/profile/${profileInviteHash}/pwInit.json`, { strValue: pw });
    }

    public static async pwForgotByInviteHash(profileInviteHash: string): Promise<ResponseMessage> {
        return DataAccess.post(
            `/match/profile/pwForgot.json?profileInviteHash=${encodeURIComponent(profileInviteHash)}`,
        );
    }
}
